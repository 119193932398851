import { useEtherSeo } from '~/composables/useEtherSeo';

/**
 * Sensible defaults for processing a graphql response for a Craft entry in a page component.
 * @param {*} response
 * @param {*} entryKey
 * @returns
 */
export function useCraftPageEther({ error, data, pending }, entryKey = 'entry') {
    const craftEntry = computed(() => {
        return data?.value?.[entryKey];
    });

    const craftSeoData = computed(() => {
        return craftEntry.value;
    });

    useEtherSeo(craftSeoData);

    watchEffect(() => {
        if (pending.value === true) {
            // Don't do anything while loading
            return;
        }
        if (!craftEntry?.value && unref(error)) {
            showError({
                statusCode: error.value?.statusCode || -1,
                statusMessage: error.value?.statusMessage || 'Internal server error',
                message: error.value?.toString(),
            });
        } else if (!craftEntry?.value) {
            showError({ statusCode: 404, statusMessage: 'Page Not Found' });
        }
    });

    return {
        craftEntry
    };
}
